<template>
	<footer>
		<div class="footer-element">
			<div class="row">
				<div class="columns column3">
					<div class="footer-logo" />
				</div>
				<div class="columns column3 contact-info">
					<h3>{{ $t('contact') }}</h3>
					<p>
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<font-awesome-icon icon="fa-light fa-phone" size="1x" />
							<span>{{ defaults[locale].website.phone }}</span>
						</a>
						<a :href="`mailto:${defaults[locale].website.email}`">
							<font-awesome-icon icon="fa-light fa-envelope" size="1x" />
							<span>{{ defaults[locale].website.email }}</span>
						</a>
					</p>
					<social-media :socials="socials" />
				</div>
				<div class="columns column2">
					<h3>{{ defaults[locale].website.hotelName }}</h3>
					<p>
						<span>{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}</span
						><br />
						<span>{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}</span
						><br />
						<span>{{ defaults[locale].website.country }}</span
						><br />
					</p>
				</div>
				<div class="columns column2">
					<h3>{{ $t('footerNavigate') }}</h3>
					<p class="footer-nav">
						<nuxt-link v-for="item in footermenu[locale]" :key="item.filename" :to="item.filename">
							{{ item.header }} <br />
						</nuxt-link>
					</p>
				</div>
				<div class="columns column2">
					<a
						href="https://magazine.natgeotraveller.co.uk/thecollection_car_aug_23/morena_eco_resort/"
						target="_blank"
						class="national-geo-logo"
					/>
				</div>
			</div>
			<div class="row">
				<div class="columns column12">
					<hr />
				</div>
			</div>
		</div>
		<div class="footer-element author">
			<div class="row">
				<div class="columns column12 align-center">
					<p class="align-left">
						<a :href="$t('siteByUrl')" target="_blank" rel="noopener noreferrer"> Hotel Website Design </a>
						by:
						<a :href="$t('siteByUrl')" target="_blank">
							<img class="siteByLogo" :src="$t('siteByLogo')" loading="lazy" alt="Site by" />
							Porter
						</a>
					</p>
					<p class="align-right">
						<span v-for="(item, idx) in bottomfooter[locale]" :key="item.filename">
							<nuxt-link :to="item.filename">
								{{ item.header }}
							</nuxt-link>
							<span v-if="idx < bottomfooter[locale].length - 1">|</span>
						</span>
					</p>
				</div>
			</div>
		</div>
	</footer>
</template>

<script setup>
const { data: footer } = await useWebsiteFetch('footermenu');
const { footermenu, bottomfooter } = footer.value;
const { locale } = useI18n();

const { defaults, socials, fetchDefaults } = useDefaults();

await fetchDefaults();
</script>

<style lang="scss" scoped>
.footer-element {
	background: var(--contentblock-background-color);
	color: #fff;
	padding: 75px 0 10px;
	font-size: 15px;
}

.footer-element hr {
	background: #fff;
	margin: 40px 0 0;
}

.footer-element a {
	color: #fff;

	&:hover {
		color: var(--cta-color);
	}
}

.footer-element h3 {
	color: #fff;
	font-size: calc(var(--body-font-size) - 4px);
	letter-spacing: 1px;
}

.footer-element.author {
	padding: 10px 0;
	font-size: 12px;
}

.author .column12 {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
}

.contact-info {
	a {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;

		svg {
			margin: 0 15px 0 0;
		}

		span {
			max-width: calc(100% - 30px);
		}
	}
}

.align-right a {
	margin: 0 7px;
}

.author img {
	max-width: 20px;
	margin: 0 1px 0 2px;
}

.footer-logo {
	width: 100%;
	max-width: 264px;
	height: 79px;
	background: url('~/assets/images/logo-morena-resort-white-footer.png') no-repeat center center;
	background-size: 100%;
	margin: 0 auto 20px;
}

.national-geo-logo {
	display: block;
	width: 100%;
	max-width: 200px;
	height: 161px;
	background: url('~/assets/images/logo-national-geographic-white.png') no-repeat center center;
	background-size: 100%;
	margin: 0;
}

.socials a {
	color: #fff;
	background: var(--cta-color);
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	font-size: 22px;
	margin: 5px 5px 5px 0;
	text-decoration: none;
}

.socials a:hover {
	color: #fff;
	background: var(--cta-color-hover);
}

@media (max-width: 920px) {
	.footer-element .columns {
		margin: 20px auto;

		&:last-child {
			width: 100%;
		}
	}

	.national-geo-logo {
		margin: 0 auto;
	}

	.author .columns {
		margin: 0;
		flex-flow: wrap column-reverse;
	}

	.author p {
		width: 100%;
		margin: 0 0 5px;
		text-align: center;
	}
}

@media (max-width: 680px) {
	.contact-info {
		a {
			justify-content: center;
		}
	}

	.footer-element {
		text-align: center;
	}
}
</style>
